@use '@tsp-ui/core/sass/utils';

.root {
  max-width: 1080px !important;
}

.container {
  display: flex;
  flex-direction: column;
  gap: utils.spacing(2);
}

.table {
  padding: 0;
  border: 0;
  margin-inline: utils.spacing(2);
  margin-top: utils.spacing(1);
}

.loader {
  margin-inline: utils.spacing(2);
  margin-top: utils.spacing(1);
}

.buttonCell {
  display: flex;
  justify-content: flex-end;
}

.noResults {
  padding: utils.spacing(1, 0);
  margin-inline: utils.spacing(2);
  margin-top: utils.spacing(1);
}
