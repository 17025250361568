@use '@tsp-ui/core/sass/utils';

.container {
    max-width: 1200px !important;
}

.table {
    padding: 0;
    border: 0;
}

.iconButtonsContainer {
   display: flex;
   justify-content: flex-end;
}
