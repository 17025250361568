@use '@tsp-ui/core/sass/utils';

.root {
    max-width: 1400px !important;
}

.progress {
    margin: utils.spacing(0.5, 6, -0.5, 6);
}

.content {
    display: flex;
    justify-content: space-between;
    gap: utils.spacing(4);
}

.card {
    min-width: 300px;
    align-self: flex-start;
}

.labeledValue {
    word-break: break-all;
    white-space: wrap;
}

.tableWrapper {
    margin-bottom: utils.spacing(-1);
    background-color: #eeeeee;
}

.table {
    padding: 0;
    border: 0;

    td {
        padding: utils.spacing(1) !important;
    }

    td,
    th {
        text-align: center;
    }

    td:first-child,
    th:first-child {
        text-align: left;
    }
}