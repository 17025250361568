@use '@tsp-ui/core/sass/utils';

.form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 500px;
    gap: utils.spacing(2);
    align-items: center;
}

.secret {
    grid-column: span 2;
}