@use '@tsp-ui/core/sass/utils';

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: utils.spacing(1, 0);
  height: 60px;
  width: 85px;
  border-top-right-radius: var(--app-border-radius);
  border-top-left-radius: var(--app-border-radius);
  transition: background-color ease 250ms;

  &.selected {
    background: var(--app-color_background);
    color: var(--app-color_primary);
    border: 2px solid var(--app-color_primary);
    border-bottom-color: var(--app-color_background);
    margin-top: 1px;

    &.dark {
      background-color: var(--app-color_primary_very-dark);
    }

    &.suppressBorder {
      border: none;
    }
  }

  &.subItem {
    height: auto;
  }
}

.icon {
  font-size: 28px;
}

.label {
  margin: utils.spacing(0.5, 0, 0.5, 0);
  line-height: 1.1;
}
