@use '@tsp-ui/core/sass/utils';
@use 'scss/dpr-admin-theme';

.root {
  display: grid;
  grid-template:
        "header" #{dpr-admin-theme.$header-height}
        "main"/ 1fr;
}

.headerContainer {
  grid-area: header;
  background-color: white;
  border-bottom: 2px solid var(--app-color_primary);
}

.toolbar {
  height: #{dpr-admin-theme.$header-height};
  margin: utils.spacing(0, 3);
  gap: utils.spacing(3);
}

.logo {
  padding-block: utils.spacing(1);
  height: 64px;
  position: relative;
  top: -2px;
}
