@use '@tsp-ui/core/sass/utils';

.content {
    display: flex;
    flex-direction: column;
    gap: utils.spacing(2);
}

.labelContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.hostLabel {
        grid-column: span 2;
        gap: utils.spacing(2);
    }
}
