@use '@tsp-ui/core/sass/utils';

.nav {
    display: flex;
    align-items: flex-end;
    color: var(--app-color_text-secondary);
    margin-right: auto;
    margin-top: 3px;
}

.logOut {
    margin-left: utils.spacing(2);
}
