@use '@tsp-ui/core/sass/utils';

.main {
  grid-area: main;
  margin: auto;
  padding: utils.spacing(0, 6, 6, 6);
  max-width: 1366px;
  width: 100%;
  height: 100%;
}

.toolbar {
  display: flex;
  align-items: center;
  gap: utils.spacing(3);
}

.header {
  margin: utils.spacing(3, 0);
}

.headerActionsContainer {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: utils.spacing(2);
  position: absolute;
  right: 0;
}

.pageMessageContainer {
  position: relative;
  overflow: hidden;
  z-index: 1;
}

.main.centered {
  display: flex;
  flex-direction: column;
  align-items: center;

  & .toolbar {
    align-self: stretch;
  }

  & .header {
    width: 100%;
  }

  & .pageMessageContainer {
    position: absolute;
  }
}

.breadcrumbs {
  margin-block: utils.spacing(-3, 2);
  position: relative;
  z-index: 1;
}

.loader {
  align-self: stretch;
}
